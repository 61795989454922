.App {
  text-align: center;
}

.logo {
  height: 3rem;
}

.leaflet-container {
  width: 100wh; 
  height: 50vh;
}